import React from "react"
import { navigate, graphql } from "gatsby"
import { SEO, Section, Heading, Link } from "components"
import words from "lodash.words"
import deburr from "lodash.deburr"

const IndexPage = ({ data, location }) => {
  const searchParam = new URLSearchParams(location.search).get("q")
  const isBrowser = typeof window === "object"

  if (!searchParam) {
    if (typeof window !== "undefined") {
      navigate("/")
    }

    return <></>
  }

  const pages = data.allSitePage.edges
  const searchTerms = words(deburr(searchParam.toLowerCase()))

  const searchResults = pages.filter(({ node: page }) => {
    if (!page.context || !page.context.description) {
      return false
    }

    const description = deburr(page.context.description).toLowerCase()
    const title = deburr(page.context.pageTitle).toLowerCase()
    const categories = deburr(page.context.categories.toString()).toLowerCase()

    console.log(title, categories)

    return searchTerms.every(
      term =>
        title.includes(term) ||
        description.includes(term) ||
        categories.includes(term)
    )
  })

  console.log(searchResults)

  return (
    <>
      <SEO title={`Resultados para ${searchParam}`} />
      <Section container>
        {searchResults.length === 0 && (
          <div>
            Não foram encontrados resultados para <strong>{searchParam}</strong>
            . Experimente buscar novamente por outro produto ou termo.
          </div>
        )}

        {searchResults.length > 0 && (
          <div>
            <Heading>Resultados para {searchParam}</Heading>

            <div className="space-y-8">
              {searchResults.map(
                ({
                  node: {
                    path,
                    context: { pageTitle, description },
                  },
                }) => {
                  {
                    /* if(str.length > 10) str = str.substring(0,10); */
                  }
                  return (
                    <div className="bg-white border-b-4 p-8 shadow-soft border-accent-500 space-y-6">
                      <div>
                        <Heading level="3" className="mb-0">
                          <Link to={path}>{pageTitle}</Link>
                        </Heading>

                        <div className="text-accent-500">
                          <Link to={path}>https://bondmann.com.br{path}</Link>
                        </div>

                        <div>
                          <Link to={path}>
                            {description.length > 150
                              ? `${description.substring(0, 175)}...`
                              : description}
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                }
              )}
            </div>
          </div>
        )}
      </Section>
    </>
  )
}

export const query = graphql`
  {
    allSitePage {
      edges {
        node {
          path
          context {
            slug
            description
            pageTitle
            categories
          }
        }
      }
    }
  }
`

export default IndexPage
